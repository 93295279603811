<!--
 * @Description: 服务商品信息新增编辑
 * @Author: LiangYiNing
 * @Date: 2021-12-22 11:15:10
 * @LastEditTime: 2022-07-15 16:44:15
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">商品基础信息</div>
            <el-form
              ref="form"
              label-width="140px"
              label-position="right"
              disabled
              :inline="true"
              :model="form"
              :rules="rules"
              class="editForm"
            >
              <el-form-item label="商品ID" prop="thirdClassId">
                <el-input
                  v-model="form.thirdClassId"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="商品分类(一级设备分类)"
                prop="firstClassName"
              >
                <div slot="label">
                  <div>商品分类</div>
                  <div>(一级设备分类)</div>
                </div>
                <el-input
                  v-model="form.firstClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="商品开票名称(二级设备分类)"
                prop="secondClassName"
              >
                <div slot="label">
                  <div>商品开票名称</div>
                  <div>(二级设备分类)</div>
                </div>
                <el-input
                  v-model="form.secondClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="商品名称(三级设备分类)"
                prop="thirdClassName"
              >
                <div slot="label">
                  <div>商品名称</div>
                  <div>(三级设备分类)</div>
                </div>
                <el-input
                  v-model="form.thirdClassName"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="商品可用状态" prop="usedState">
                <el-input
                  v-model="form.usedState"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="单位" prop="meaurement">
                <el-input
                  v-model="form.meaurement"
                  placeholder="暂无记录"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  placeholder="暂无记录"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="edit-title">商品拓展信息</div>
            <el-form
              ref="goodsMoreForm"
              label-width="140px"
              label-position="right"
              :inline="true"
              :model="form"
              :rules="rules"
              class="editForm"
            >
              <el-form-item
                label="上架状态"
                class="search-item--1"
                prop="shelfStatusCode"
              >
                <e6-vr-select
                  v-model="form.shelfStatusCode"
                  :data="shelfStatusEnum"
                  placeholder="请选择上架状态"
                  title="请选择上架状态"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item
                v-if="form.shelfStatusCode == 3"
                label="下架原因"
                prop="removeReason"
              >
                <el-input
                  v-model="form.removeReason"
                  placeholder="下架原因"
                  title="下架原因"
                  maxlength="128"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="form.shelfStatusCode == '2'"
                label="预停售时间"
                prop="preStopSaleTime"
              >
                <el-date-picker
                  v-model="form.preStopSaleTime"
                  type="datetime"
                  placeholder="预停售时间"
                  value-format="timestamp"
                  title="预停售时间"
                  :picker-options="pickerOptions1"
                  popper-class="special--full"
                ></el-date-picker>
              </el-form-item>
              <div>
                <el-form-item
                  class="introduction-content-form"
                  label="产品简介"
                  prop="productDescription"
                >
                  <el-input
                    type="textarea"
                    v-model="form.productDescription"
                    :autosize="{ minRows: 3 }"
                    placeholder="产品简介"
                    title="产品简介"
                    maxlength="512"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import { printError } from "@/utils/util";
import { updateServiceGoods, serviceGoodsDetail, findDownList } from "@/api";

export default {
  name: "ServiceGoodsUpdate",
  components: {},
  mixins: [base, goBack],
  data() {
    return {
      form: {
        thirdClassId: "", // 商品ID
        firstClassName: "", // 商品分类(一级设备分类)
        secondClassName: "", // 商品开票名称(二级设备分类)
        thirdClassName: "", // 商品名称(三级设备分类)
        usedState: "", // 商品可用状态
        meaurement: "", // 单位
        remark: "", // 备注
        shelfStatusCode: "", // 上架状态
        removeReason: "", // 下架原因
        preStopSaleTime: "",
        versionNumber: "", // 版本号
        productDescription: "" // 产品简介
      },
      shelfStatusEnum: [] // 上架状态枚举
    };
  },
  props: {},
  computed: {
    pickerOptions1() {
      return {
        disabledDate: time => {
          return time.getTime() < Date.now();
        }
      };
    },
    rules() {
      let rules = {
        shelfStatusCode: [
          {
            required: true,
            message: "请选择商品上架状态",
            trigger: ["blur", "change"]
          }
        ],
        removeReason: [
          {
            required: true,
            message: "请填写下架原因",
            trigger: ["blur", "change"]
          }
        ],
        preStopSaleTime: [
          {
            required: true,
            message: "请选择停售时间",
            trigger: ["blur", "change"]
          }
        ]
      };
      return rules;
    }
  },
  watch: {},
  mounted() {},
  created() {
    this.initData();
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    // 初始化表单
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.form.thirdClassId = hasParamsRoute.params.id;
      if (this.form.thirdClassId) {
        this.getGoodsInfo();
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [findDownList(["shelfStatus"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.shelfStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.shelfStatus"
        });
      } catch (error) {
        printError(error);
      }
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.goodsMoreForm.validate(valid => {
        if (valid) {
          this.UpdateGoodInfo();
        }
      });
    },
    //修改商品
    async UpdateGoodInfo() {
      try {
        this.loading = true;
        let res = await updateServiceGoods({
          productDescription: this.form.productDescription,
          shelfStatusCode: this.form.shelfStatusCode,
          removeReason: this.form.removeReason,
          thirdClassId: this.form.thirdClassId,
          versionNumber: this.form.versionNumber,
          preStopSaleTime: this.form.preStopSaleTime
        });
        if (res.code === "OK") {
          this.$message.success("修改成功");
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取商品详情
    async getGoodsInfo() {
      this.loading = true;
      try {
        let res = await serviceGoodsDetail({
          id: this.form.thirdClassId
        });
        if (res.code !== "OK") {
          return;
        }
        this.form = res.data;
        this.form.shelfStatusCode =
          res.data.shelfStatusCode === null ? "" : res.data.shelfStatusCode;
        this.form.shelfStatusCode += "";
        let preStopSaleTime = this.form.preStopSaleTime;
        if (preStopSaleTime) {
          preStopSaleTime = preStopSaleTime.replace(/-/g, "/");
          this.form.preStopSaleTime = new Date(preStopSaleTime).getTime();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
//新增页面form表单样式
.edit-wrapper__body {
  .el-form {
    .el-input {
      width: 250px;
    }
    .el-select {
      width: 250px;
    }
    .el-textarea {
      width: 250px;
    }
  }
}
.address-box {
  display: flex;
  align-items: center;
}
.baseInfo-box {
  .el-input {
    width: 220px;
  }
  .el-select {
    width: 220px;
  }
}
/deep/.edit-wrapper__body .editForm {
  .introduction-content-form {
    width: 60%;
    .el-form-item__content {
      width: calc(100% - 150px);
      min-width: 200px;
      .el-textarea {
        width: 100%;
      }
    }
  }
}
</style>
